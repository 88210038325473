svg {
  overflow: visible;
  display: block;
  border: 1px solid #444;

  @include lg {
    margin: 6%;
  }

  @include xl {
    margin: 8%;
  }
}

marker {
  overflow: visible;
}

path {
  stroke-linecap: round;
  fill: none;
}

.GridLine {
  stroke: $border-color;
}

.Vector {
  stroke: #bbb;
  stroke-width: 2;
  filter: drop-shadow(1px 1px 0px black);
}

#marker-arrow path {
  fill: #bbb;
}

.Image-downloadSection {
  margin-top: 20px;
}

.Image-generateDownload {
  display: block;
}

.Image-downloadNote {
  font-size: 14px;
  color: #777;
  line-height: 18px;
  max-width: 600px;
  margin-top: 40px;
}
