.Control {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  align-items: center;
}

.Control-label {
  flex-basis: 100%;
  margin-bottom: 6px;
}

.Control-inputWrapper {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(100% - 140px);
  margin-right: auto;

  input:not([type='checkbox']) {
    width: 100%;
  }
}

.Control-value {
  flex-basis: 50px;
  text-align: right;
  flex-shrink: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Controls-buttons {
  margin-top: 50px;
}

.Controls-reset {
  display: block;
}

.Controls-title {
  font-size: 26px;
  margin-bottom: 10px;
  display: inline-block;

  span {
    transition: all 250ms;
  }
}

.Controls-titleLink {
  text-decoration: none;
}

.Controls-description {
  margin-bottom: 20px;

  p {
    margin-bottom: 10px;
  }

  a {
    margin-right: 10px;
    transition: all 250ms;

    &:hover {
      color: #fff !important;
    }
  }
}
