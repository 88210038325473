* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: #aaa;
  background: #1a1c1e;
  font-family: Helvetica, Arial, sans-serif;
}

.App {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

@media (min-width: 800px) {
  .App {
    padding: 30px;
    display: flex;
  }
}

@media (min-width: 1200px) {
  .App {
    padding: 40px;
  }
}

@media (min-width: 1500px) {
  .App {
    padding: 50px;
  }
}

.Image {
  max-width: 1600px;
  width: 100%;
  margin-top: 60px;
}

@media (min-width: 800px) {
  .Image {
    margin-top: 0;
  }
}

.Controls {
  z-index: 10;
  flex-shrink: 0;
  flex-basis: 270px;
  position: relative;
}

@media (min-width: 800px) {
  .Controls {
    margin-right: 30px;
  }
}

@media (min-width: 1200px) {
  .Controls {
    margin-right: 40px;
  }
}

@media (min-width: 1500px) {
  .Controls {
    margin-right: 50px;
  }
}

.MuiSlider-root {
  color: #eee !important;
}

.MuiSlider-valueLabel {
  color: #000;
}

.Button, button {
  color: #999;
  z-index: 10;
  background: #1a1c1e;
  border: 1px solid #444;
  border-radius: 50px;
  outline: none;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 8px 18px;
  font-size: 14px;
  line-height: 1;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.Button:hover, button:hover {
  color: #bbb;
  cursor: pointer;
  border-color: #555;
}

.Button:active, button:active {
  transform: translateY(1px);
}

.Button:focus, button:focus {
  box-shadow: 0 0 0 2px #1a1c1e, 0 0 0 3px #666;
}

button::-moz-focus-inner {
  border: 0;
}

input[type="text"] {
  color: #999;
  background: #1a1c1e;
  border: 1px solid #444;
  border-radius: 2px;
  outline: none;
  padding: 6px;
  font-size: 14px;
  transition: all .3s;
  display: inline-block;
}

input[type="text"]:hover {
  color: #bbb;
  cursor: pointer;
  border-color: #555;
}

input[type="text"]:active {
  transform: translateY(1px);
}

input[type="text"]:focus {
  box-shadow: 0 0 0 2px #1a1c1e, 0 0 0 3px #666;
}

.Control {
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 15px;
  display: flex;
}

.Control-label {
  flex-basis: 100%;
  margin-bottom: 6px;
}

.Control-inputWrapper {
  flex: 1 0 calc(100% - 140px);
  margin-right: auto;
}

.Control-inputWrapper input:not([type="checkbox"]) {
  width: 100%;
}

.Control-value {
  text-align: right;
  text-overflow: ellipsis;
  flex-shrink: 0;
  flex-basis: 50px;
  overflow: hidden;
}

.Controls-buttons {
  margin-top: 50px;
}

.Controls-reset {
  display: block;
}

.Controls-title {
  margin-bottom: 10px;
  font-size: 26px;
  display: inline-block;
}

.Controls-title span {
  transition: all .25s;
}

.Controls-titleLink {
  text-decoration: none;
}

.Controls-description {
  margin-bottom: 20px;
}

.Controls-description p {
  margin-bottom: 10px;
}

.Controls-description a {
  margin-right: 10px;
  transition: all .25s;
}

.Controls-description a:hover {
  color: #fff !important;
}

svg {
  border: 1px solid #444;
  display: block;
  overflow: visible;
}

@media (min-width: 1200px) {
  svg {
    margin: 6%;
  }
}

@media (min-width: 1500px) {
  svg {
    margin: 8%;
  }
}

marker {
  overflow: visible;
}

path {
  stroke-linecap: round;
  fill: none;
}

.GridLine {
  stroke: #333;
}

.Vector {
  stroke: #bbb;
  stroke-width: 2px;
  filter: drop-shadow(1px 1px #000);
}

#marker-arrow path {
  fill: #bbb;
}

.Image-downloadSection {
  margin-top: 20px;
}

.Image-generateDownload {
  display: block;
}

.Image-downloadNote {
  color: #777;
  max-width: 600px;
  margin-top: 40px;
  font-size: 14px;
  line-height: 18px;
}

/*# sourceMappingURL=index.ca4a53b7.css.map */
